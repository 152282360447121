<template>
  <div class="clients-container">
    <modal-vue @onChange="onChangeModal" :modal="modal">
      <div class="user-delete-modal">
        <div class="user-delete-inside">
          <p class="modal-header">Delete News Category</p>
          <div class="modal-data">
            <p v-if="checkIsInUse.hasChild">
              {{deleteNewsCategoryObj.label }} cannot be deleted as it has child categories. Remove/Update child categories, and try again.
          </p>
          <p v-else-if="!checkIsInUse.hasChild && checkIsInUse.isInUse">
               {{deleteNewsCategoryObj.label}} is used by an active client subscription. Deleting the category will impact the end-users.
          </p>
          <p v-else-if="!checkIsInUse.hasChild && !checkIsInUse.isInUse">
              Are you sure you want to delete this category  {{deleteNewsCategoryObj.label}}?
          </p>

          <div class="actions">
            <b-button @click="onChangeModal(false)">Cancel</b-button>
            <b-button v-if="!checkIsInUse.hasChild" @click="removeNewsCategory">Delete</b-button>
          </div>
          </div>
        </div>
      </div>
    </modal-vue>
    <admin-header-vue
      v-if="showCategory"
      :alias="headerAlias"
      :canCreate="true"
      @onCreate="onCreate"
      pageName="" />
    <div class="user-table">
      <table-vue
        :labels="labels"
        :dateFormat="dateFormat"
        :data="getCategoriesData()"
        :sortObj="sort"
        :canDelete="true"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :showEditAction="showEdit"
        @onUpdate="onUpdate"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete"
        :limit="limit"
        :titleData="titleData"
        :sortValidation="sortValidation"
        :categoryFilterNeeded="false"
        @searchText="searchText"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @updateLimit="updateLimit"
        @onDelete="onDelete">
      </table-vue>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import { metaTitle } from '../../../../constant/data'
import ModalVue from '../../../Admin/components/Modal.vue'

/** This component contains the code that allows you to view the categories present in the database and their details. From here the user can view/edit existing categories and navigate to the *Add categories* page. */
export default {
  name: 'newsCategories',
  metaInfo () {
    return {
      title: metaTitle.newsCategories
    }
  },
  components: {
    tableVue,
    AdminHeaderVue,
    ModalVue

  },
  data () {
    return {
      headerAlias: 'News Category',
      titleData: 'newsCategories',
      dateFormat: ['createdAt', 'updatedAt'],
      deleteNewsCategoryObj: {},
      showWarning: false,
      labels: [
        { name: 'Category Id', alias: 'id' },
        { name: 'Category Name', alias: 'label' },
        { name: 'Parent Category', alias: 'parent' },
        { name: 'Created At', alias: 'createdAt' },
        { name: 'Updated At', alias: 'updatedAt' }
      ],
      sortValidation: ['id', 'label', 'createdAt', 'updatedAt'],
      search: null,
      sort: { by: 'asc', field: 'id' },
      categoryFilterNeeded: true,
      modal: false
    }
  },
  computed: {
    ...mapState('newsCategories', ['categories', 'totalCount', 'checkIsInUse']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    showCategory () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.category.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.category.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.category.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.category.currentPage = newPage
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions('newsCategories', ['getNewsCategoryListPagination', 'checkNewsCategoryInUse', 'deleteNewsCategory']),
    /** This method selects the news category to be deleted, and triggers the opening of a confirmation modal.
     * @param data {Object} - The news category to be deleted
     * @public
     */

    async onDelete (data) {
      this.deleteNewsCategoryObj = data
      const res = await this.checkNewsCategoryInUse(this.deleteNewsCategoryObj.id)
      if (res === 200) {
        this.modal = true
      }
    },

    /** This method toggles the visibility of the modal
     * @public
     */
    onChangeModal (value) {
      this.modal = value
    },
    /** This method deletes the news category from the database and refreshes the table
     * @public
     */
    async removeNewsCategory () {
      let res = null
      res = await this.deleteNewsCategory(this.deleteNewsCategoryObj.id)
      if (res === 200) {
        this.modal = false
        this.getData()
      }
    },
    getCategoriesData () {
      return this.categories.map((ele) => {
        if (ele.parent !== null) {
          return { ...ele, parent: ele.parent.label }
        } else {
          return ele
        }
      })
    },

    /** This method redirects the user to the *add category* page
     * @public
     */
    onCreate () {
      this.$router.push('news-categories/add')
    },

    /** This method updates the sorting criteria of the table
     * @public
     */
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    /** This method updates the table page that is being viewed
     * @public
     */
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    /** This method updates the number of rows that  are being shown by the table
     * @public
     */
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    /** This method updates the search keyword and triggers the *getData()* method
     * @param text {String} - The new search keyword
     */
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    /** This method fetches the category related data from the database, using the limit/search/sort parameters specified */
    getData () {
      if (this.search === '') {
        this.search = null
      }
      this.getNewsCategoryListPagination({
        status: 'all',
        limit: this.limit,
        offset: (this.currentPage - 1) * this.limit,
        orderBy: this.sort.field,
        order: this.sort.by,
        searchQuery: this.search
      })
    },
    /** This method redirects the user to the *update news-category* page/form and populates it with existing news category data from the database
   * @param data {Object} - The category object that contains the details for the selected category
   */
    onUpdate (data) {
      this.$router.push(`news-categories/update/${data.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .clients-container {
    margin: 20px 0px;
    border-radius: 4px;
    background: var(--primary);
    .relative {
      position: relative;
    }
    .user-delete-modal {
      display: flex;
      justify-content: center;
      .user-delete-inside {
        display: flex;
        color: var(--secondary-text-color);
        font-family: Quicksand;
        flex-direction: column;
        background: var(--dropdown-backgroud);
        border-radius: 4px;
        width: 400px;
        height: 220px;
        padding: 25px;
        .modal-data {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          p {
            font-size: 14px;
          }
        }
        .modal-header {
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 10px;
          text-align: left;
        }
      }
      .actions {
        padding-top: 20px;
        text-align: end;
        .button {
          height: 32px;
          border: none;
          font-size: 14px;
          width: 75px;
        }
        .button:nth-child(1) {
          margin-left: 5px;
          background-color: transparent;
          color: var(--secondary-text-color);
          border: 1px solid var(--primary-border-color);
          &:hover {
            border: 2px solid var(--primary-border-color);
            transition: border 0.3s ease-out;
          }
          &:active {
            background-color: #7f8fa466;
            transition: background-color 0.3s ease-out;
          }
        }
        .button:nth-child(2) {
          margin-left: 5px;
          background-color: #ff0000;
          color: white;
          &:hover {
            background-color: #f64a4a;
            transition: background-color 0.3s ease-out;
          }
          &:active {
            background-color: #ee1111;
            transition: background-color 0.3s ease-out;
          }
        }
      }
    }
  }
</style>
